<template>
    <div>
        <zw-select-group v-model="inputVal"
                         :options="getCorrectPriceLists()"
                         name="new_value"
                         :label-prefix="labelPrefix"
                         text-field="name"
                         value-field="id"
        ></zw-select-group>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    methods: {
        ...mapGetters('CommonData', ['getPriceLists']),
        getCorrectPriceLists() {
            let list = [{
                id: null,
                name: 'Default'
            }]
            for (const [key, value] of Object.entries(this.getPriceLists())) {
                list.push({
                    id: key,
                    name: value,
                })
            }
            return list
        },
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>